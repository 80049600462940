import './App.css';
import { Container } from 'react-bootstrap';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          PEC Data Dashboard
        </p>
      </header>
      <body>

        <iframe title="Report Section" width="100%" height="920px" src="https://app.powerbi.com/view?r=eyJrIjoiZDJkMzdjYTctMjIwNi00NWQ1LTlhZDMtMTNkZjI1MTI4NzAyIiwidCI6IjZiZDdjYmI5LWNhNTUtNGI0YS1hNjFjLWI5MTZhNTZhYWRjOSIsImMiOjEwfQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>

      </body>
    </div >
  );
}

export default App;
